<template>
	<div v-if="loaded" class="opacity-page table">
		<div class="settings-content">
			<div class="list-crud row-space-tbf">
				<div class="space-left"></div>
				<div class="content xml">
					<div class="crud" id="list-items">
						<div class="box">
							<div class="header">
								<div class="name">{{$t('salary.d112.generate')}}</div>
							</div>
							<div class="form">
                                <div class="input-group mb-15 checkboxes-group checkbox-xml">
                                    <label class="checkbox-tbf no-margin">
                                        <span class="text-checkbox error-msg-terms" v-html="$t('salary.d112.is_rectification')" />
                                        <input type="checkbox" value="1" v-model="is_rectification">
                                        <span class="checkmark"></span>
                                    </label>						
                                </div>
								<div class="input-group">
									<div class="label-header">
										<label class="label">{{$t('salary.d112.rectification_type')}}</label>
                                        <!-- <div v-if="$v.selectedRectification.$error" class="error-msg">{{ $t('validator.required') }}</div> -->
									</div>

									<div class="input-box" v-bind:class="[{'bg-white': is_rectification},{'bg-gray': !is_rectification}, {has_error: $v.selectedRectification.$error}]">
										<div class="icon-left"><icon-rectification /></div>
										<div class="icon-right" v-if="selectedRectification != ''" @click.stop="selectedRectification = ''"><icon-close class="icon-clear" /></div>
										<multiselect 
										v-model="selectedRectification"
										v-bind:class="{populate: selectedRectification != ''}"
										:options="rectificationOptions"
										:allow-empty="true"
										:show-labels="false"
										track-by="id" 
										label="name"
                                        :disabled="!is_rectification"
										>
											<template slot="placeholder" slot-scope="props">
												<span class="text">
													{{$t('salary.d112.rectification_type-ph')}}
												</span>
											</template>
											<template slot="noResult">{{$t('departments.no-results')}}</template>
											<template slot="noOptions">{{$t('departments.empty-list')}}</template>
										</multiselect>
									</div>
								</div>
								<div class="form-submit">
									<button id="buttonCreate" class="btn-tbf blue" @click="saveAction('create')">
										<div class="loader"></div>
										<span class="text">{{$t('salary.d112.generate')}}</span>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="space-right"></div>
			</div>
		</div>
	</div>
	<loader-settings-page v-else/>
</template>

<script>
    import IconRectification from '../../../Icons/Rectification'
    import IconClose from '../../../Icons/Close'
	import LoaderSettingsPage from '../../../PagesLoaders/SettingsNomenclature'
    import {requiredIf} from 'vuelidate/lib/validators'

    export default {
    	components: {
            IconRectification,
            IconClose,
			LoaderSettingsPage
        },
        data() {
            return {
            	loaded: false,
            	
                rectificationOptions: [],
                selectedRectification: '',
                is_rectification: 0,
				payroll_date: {}
            }
        },

        validations: {
            selectedRectification: { required: requiredIf(function () {
                return this.is_rectification
            }) },
        },
        async mounted(){
			if(this.$route.params.data) {
				this.payroll_date = this.$route.params.data
			}
			
            await this.getD112Types()

			setTimeout(() => {
				var title = this.$t('salary-navbar.d112');
				this.$root.$emit("navbar_title", title);
			}, 0)

			this.$root.$on('refreshSalaryIndex', (date) => {
				this.payroll_date.month = date.month
				this.payroll_date.year = date.year
			})
        },
        methods: {
            getD112Types() {
                axios.get('/d112-rectification-types').then(({data}) => {
                    this.rectificationOptions = data.data
                }).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				}).finally(() => {
					this.loaded = true
					setTimeout(() => {
						$('.opacity-page').addClass('show')
					}, 0)
				})
            },
            
			saveAction(type){
				var buttonName = `button${ type == 'create' ? 'Create' : 'Update'}`
				var btnSubmit = document.getElementById(buttonName);
				btnSubmit.disabled = true

				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)

				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')
				this.error_message = ''

				this.$v.$touch()
				if(!this.$v.$invalid){
					let objData = {};
                    objData.month = this.payroll_date.month
                    objData.year = this.payroll_date.year
                    objData.is_rectification = this.is_rectification
                    objData.rectification_type = this.selectedRectification ? this.selectedRectification.code : ''
					

					this.generateXml(objData, btnSubmit, btnSubmitText, btnSubmitLoader)
				}else{
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = this.$t('salary.d112.generate') 
							btnSubmit.disabled = false
						}, 1000)
					}, 300)
				}
			},
			
			generateXml(objData, btnSubmit, btnSubmitText, btnSubmitLoader){
				axios.get(`/d112/create`, {params: objData})
				.then((response) => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('completed')
							btnSubmitText.innerHTML = this.$t('salary.d112.generate')
                            let blob = new Blob([response.data], { type:"application/xml" })
                            let fileURL = window.URL.createObjectURL(blob);

                            let fileName = 'D112' + '_' + this.payroll_date.month + '_' + this.payroll_date.year+ '.xml';
                            let fileLink = document.createElement('a');
                            fileLink.href = fileURL;
                            fileLink.setAttribute( 'download', fileName );
                            document.body.appendChild(fileLink);

                            fileLink.click();
						}, 1000)
					}, 300)
				})
				.catch(error => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = this.$t('salary.d112.generate')
							btnSubmit.disabled = false
							if(error.response.status == 500){
								this.error_message = this.$t('error.500')
							}
							this.error_message = Object.values(error.response.data.errors).join('')
						}, 1000)
					}, 300)})
				.finally(() => {
					setTimeout(()=>{
						btnSubmit.disabled = false
					}, 1500)
				})
			},
        }
    }
</script>

<style lang="scss">

.d112 {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 30px 0;
    .content{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .actions-page{
            display: flex;
            button {
                margin-right: 15px;
            }
        }
    }
}

.content{
    &.xml{
        justify-content: center;
        .multiselect__single{
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            font-weight: normal;
            white-space: pre-wrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
		.text-checkbox {
			font-weight: 400 !important;
		}
		.label-header {
			.label {
				font-weight: 400 !important;
			}
		}
    }
}

</style>